import { createI18n } from 'vue-i18n';
import de from './locales/de.json';
import en from './locales/en.json';

const userLanguage = navigator.language || navigator.userLanguage;
const userLocale = userLanguage.startsWith('de') ? 'de' : 'en';

export default createI18n({
	locale: userLocale,
	fallbackLocale: 'de',
	messages: { de, en },
	legacy: false,
});
