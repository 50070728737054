const routes = [
	{
		path: '/',
		component: () => import('@/views/HomeView.vue'),
	},
	{
    path: '/impressum',
    component: () => import('@/views/ImpressumView.vue'),
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'NotFound',
		component: () => import('@/views/NotFound.vue'),
	  }
];

export default routes;